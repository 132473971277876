
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getUrlParam } from 'wd-platform-utils'
import * as dd from 'dingtalk-jsapi'
import { RedirectRouteParam, getRedirectType, mobileGetRedirectType } from '@/enmu/redirect'
import { getCORPID, getDingDingAPPID } from '@/utils/prototypeAPI'
import { isMobile } from '@/utils/request'
export default defineComponent({
    name: 'PaySuccess',
    setup () {
        const router = useRouter()
        const appCode = ref<string | null>(null)
        const redirectUrl = ref<string>('')
        const currentUrl = ref('')
        const platformVail = ref<string>(isMobile() ? 'mobile' : 'pc')
        onMounted(() => {
            currentUrl.value = window.location.href
            appCode.value = getUrlParam('appCode') || null
            getDingdingCode()
        })
        const getDingdingCode = () => {
            dd.ready(() => {
                dd.runtime.permission.requestAuthCode({
                    corpId: getCORPID(),
                    onSuccess: (result: any) => {
                        redirectProject(result.code)
                    },
                    onFail: () => {
                        router.push('/login')
                    }
                })
            })
        }
        const onClick = () => {
            getDingdingCode()
        }
        const redirectProject = (userCode: string) => {
            if (appCode.value) {
                let param = ''
                // 判断需要传递的值 组装键值
                for (const key in RedirectRouteParam) {
                    if (getUrlParam(key)) {
                        param += `&${key}=${getUrlParam(key)}`
                    }
                }
                if (platformVail.value === 'mobile') {
                    if (appCode.value === 'finance') { // 审批移动端兼容老逻辑
                        param += '&id=' + getUrlParam('detailId')
                    } else if (appCode.value === 'znpt' || mobileGetRedirectType[appCode.value]) { // 判断是否是移动端项目
                        router.push('/forward?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID() + param)
                    } else {
                        router.push('/notmobile')
                    }
                } else {
                    redirectUrl.value = 'dingtalk://dingtalkclient/page/link?url=' + (process.env.VUE_APP_ZNPT as string) + encodeURIComponent(getRedirectType.znpt + '/forward' + '?code=' + userCode + '&clientType=dingding&appid=' + getDingDingAPPID() + param) + '&pc_slide=false'
                    window.location.href = redirectUrl.value
                }
            } else {
                router.push('/login')
            }
        }
        return {
            platformVail,
            getDingdingCode,
            onClick,
            redirectProject,
            appCode
        }
    }
})
